import React from 'react';
import './Loader.css';

const Loader = () => (
  <div className="spinner" role="progressbar">
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
);

export default Loader;
