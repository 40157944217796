const options = [
  {
    title: 'Faction',
    value: 'faction'
  },
  {
    title: 'Type',
    value: 'type'
  },
  {
    title: 'Cost',
    value: 'cost'
  },
  {
    title: 'Strength',
    value: 'strength'
  },
  {
    title: 'Subroutines',
    value: 'subroutines'
  },
  {
    title: 'Agenda Points',
    value: 'agenda'
  },
  {
    title: 'Pack',
    value: 'pack'
  },
  {
    title: 'Title',
    value: 'title'
  },
  {
    title: 'Illustrator',
    value: 'illustrator'
  }
];

export default options;
